import React from 'react';
import './App.scss';

import { TermsAndConditions, Header, FAQ, DataAndPrivacy } from './container';
import { Navbar } from './components';

const App = () => {
  return (
    <div className='app'>
      <Navbar />
      <Header />
      <TermsAndConditions />
      <DataAndPrivacy />
      <FAQ />
    </div>
  )
}

export default App;
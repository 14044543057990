import React from 'react';
import { AppWrap, MotionWrap } from '../../wrapper';
import FQ from '../../assets/FQ.pdf'
import './Skills.scss';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useState } from 'react';

const dataCollection = [
  {
    question: <div><br></br>How much does a new website cost?</div>,
    answer: <div> <br></br>This question does not have a single correct response. The cost of a website design is determined by the requirements of each specific project. We create and construct custom websites exclusively for your business because every website is distinct and needs various components. We'll elicit a lot of information from you in order to evaluate your demands and provide you with a price. Our sites typically cost $5,000 or less, however prices might vary based on requirements.
    </div>
  },
  {
    question: <div><br></br>How will a well-designed website help my Business?</div>,
    answer: <div> <br></br>The user experience will be improved with a well-designed website, which is highly important to enhance conversions. Your clients and visitors will find your website to  be aesthetically beautiful when it is built with a well-structured design, which will attract more and more prospective consumers. Getting your visitors and customers interested is the first step to your success, and you can only do so by providing a well-designed website.
    </div>
  },
  {
    question: <div><br></br>How long will it take to get a new website?</div>,
    answer: <div> <br></br>Our standard websites (Web2 & Web3) take approximately 90-120 days to create. Our E-commerce (online store) websites take approximately 120 days to create. This time will vary from project to project.
    </div>
  },
  {
    question: <div><br></br>Who will I work with during the project?</div>,
    answer: <div> <br></br>You should inquire about this with any web design and development company you are considering since it is an excellent question to ask. Many businesses may assign tasks to interns or freelancers. Clients who wish to communicate with their designers or developers directly may receive little to no access from some businesses. Direct client interaction is not only possible but routine with our close-knit team. Depending on the demands of your project and the stage of the project you are in, someone from our team will always be within reach. The Founder, Mayank, actively participates throughout the entire process.
    </div>
  },
  {
    question: <div><br></br>Will you maintain my site for me?</div>,
    answer: <div> <br></br>A website needs routine maintenance to maintain its adaptability and dependability. The security of your website would be enhanced, more people would be attracted, traffic would increase, and more with proper maintenance. Of course, we can assist you with correct and expert website upkeep in addition to creation, as this helps to achieve steady growth and maintain the direction of your organisation.
    </div>
  },
  {
    question: <div><br></br>Will my website be mobile-friendly?</div>,
    answer: <div> <br></br>Definitely! Having a mobile-friendly website is more important than ever! We work hard to ensure your website looks great on a variety of devices.
    </div>
  },
  {
    question: <div><br></br>Do I fully own the website you are building?</div>,
    answer: <div> <br></br>Absolutely! You will own the entirety of what we construct. The majority of our clients remain with us throughout the lifespan of the website. That makes our team just impossible to resist. However, if for any reason you decide that you would want to move your website to a different hosting provider and hire a different firm to maintain it, we would be happy to help you make the switch as smoothly and successfully as possible. After all, the website is yours, and we want you to take it with you wherever you go. We also provide source codes for your website at affordable prices.
    </div>
  },
  {
    question: <div><br></br>Do you outsource any aspects of the project?</div>,
    answer: <div> <br></br>No part of our projects is outsourced by us! Our crew will be your partner during the entire process.
    </div>
  },
  {
    question: <div><br></br>How does the payment process work?</div>,
    answer: <div> <br></br>A 50% down payment is required to start the project, and the remaining amount is paid during the duration of development in 4 monthly installments.
    </div>
  },
  {
    question: <div><br></br>What kind of businesses do you work with?</div>,
    answer: <div> <br></br>We operate in a variety of commercial sectors, including technology, food, clothing, health + beauty, camps, travel, finance, the arts, fair trade, and others, with a variety of business models [including tiny start-ups, big enterprises, charities, B2B, and more].<br></br>We've assisted companies with marketing their goods, enhancing customer service, and luring clients over the years. Basic to complex functionality requests are addressed and to make sure your site meets all of your objectives, our staff takes the time to learn about your business, industry, and rivals.
    </div>
  },
  {
    question: <div><br></br>How are the websites built?</div>,
    answer: <div> <br></br>Understanding your needs helps us develop your website. We make care to set up the best and most appropriate domain name, hosting companies, unique design, and content. In addition, we construct your website with SEO and performance optimization in mind.
    </div>
  },
  {
    question: <div><br></br>Can you help me update my existing website that another web firm built?</div>,
    answer: <div> <br></br>No. Only websites that we have completely created are supported by us. This policy allows us to fully stand behind anything we create. Liability difficulties arise when several programmers work on the same piece of code over time.
    </div>
  },
  {
    question: <div><br></br>What if I need help on my site down the road?</div>,
    answer: <div> <br></br>You are welcome to get in touch with us whenever you need help. Our experts can fix it and restore the operation of your site. At MUNU & MOTI™, we first investigate the underlying issues before checking all technical settings to offer the best option for restoring your website. Additionally, we would advise hiring a maintenance provider for your website.
    </div>
  },
  {
    question: <div><br></br>Who hosts the website?</div>,
    answer: <div> <br></br>In order to develop your website and make it accessible online, you must select a hosting service. Some of the top hosting companies on the market are Hostinger, Bluehost, Fastcomet, Interserver.net, A2Hosting, and others.
    If you are unfamiliar with these hosting services, we can help you with your current provider if you already have one, or we can propose the finest one that meets your needs.
    </div>
  },
  {
    question: <div><br></br>How much does hosting cost?</div>,
    answer: <div> <br></br>The price varies depending on the type of hosting you select and the hosting service provider.
    </div>
  },
  {
    question: <div><br></br>How many pages do I get with my website?</div>,
    answer: <div> <br></br>Depending on your needs, we can work on it.
    </div>
  },
  {
    question: <div><br></br>Do you do SEO for my website when you build it?</div>,
    answer: <div> <br></br>Yes, we do provide a basic SEO setup package that includes services like metadata setup, tracking setup, and analytics setup. For more optimization, clients can request monthly quotes for additional SEO services. Working on a website's SEO is essential since it dramatically increases traffic and conversions.
    <br></br>We conduct SEO optimization for your website because it's one of the essential procedures that will boost your chances of acquiring plenty of leads.
    </div>
  },
  {
    question: <div><br></br>Can you help me write content for my website?</div>,
    answer: <div> <br></br>Without a doubt, we can assist you in expanding the material on your website. By comprehending your needs and company goals, our team of copywriters can help you generate excellent material.
    <br></br>At MUNU & MOTI™, we provide material for service pages, landing pages, and blogs that are pertinent to your company's operations and are updated on a regular basis.
    </div>
  },
  {
    question: <div><br></br>I don’t like my current website, can you help?</div>,
    answer: <div> <br></br>Our goal is to assist you. Your website may be updated and improved with speed and SEO optimization, boosting your online presence.
    </div>
  },
  {
    question: <div><br></br>I already have a domain name, can I use this for my website?</div>,
    answer: <div> <br></br>That sounds wonderful. If you already have one and it works for your company, we can certainly move forward with it.
    </div>
  },
  {
    question: <div><br></br>What if I am not happy with my web design, do you offer a refund?</div>,
    answer: <div> <br></br>I really hope such things never happen. We make it a point to update on our progress and solicit your advice at each level of development. Our standard procedure is to provide the customer an update every week on what has been accomplished and to keep working in accordance with their ideas.
    </div>
  },
  {
    question: <div><br></br>I can get a free template using a company like Wix. Why should I pay you to design a website for me?</div>,
    answer: <div> <br></br>You're correct. The market does provide free website design templates, but you might not have complete flexibility to modify them to meet your needs. In addition, choosing a pre-built template for a website won't make you stand out from the competition. With some creativity and expertise applied, we can construct your website with distinctive designs that are tailored to your demands.
    <br></br>Also, the as-such free templates provided are not user-friendly and can complicate basic steps that are required to create a functional website. Not only this, there are numerous hidden costs associated with designing a website using services such as mentioned above.
    </div>
  },
  {
    question: <div><br></br>Do I get to see the design before it’s built?</div>,
    answer: <div> <br></br>Yes, without a doubt. We get our clients to confirm the design in advance. This would enable us to make the necessary adjustments prior to beginning the development process.
    </div>
  },
  {
    question: <div><br></br>Can you design my logo?</div>,
    answer: <div> <br></br>Yes, thanks to the skilled designers we employ, we can assist you in creating a logo that is appropriate for your company. We guarantee that your logo will be original, artistic, and appealing.
    </div>
  },
  {
    question: <div><br></br>What types of websites have you designed? Do you have any examples of some of the websites you’ve designed?</div>,
    answer: <div> <br></br>We have designed numerous Portfolio, Landing Pages, E-Commerce Stores, Subscription-based Content Platform, Blogs, Fintech websites, Blockchain-based sites like NFT Marketplaces, Crypto wallets, Content Platforms, and many more.
    <br></br>To learn more about what we do, browse through our portfolio. Consider looking at our web design services as well.
    </div>
  },
  {
    question: <div><br></br>Do you provide any additional services apart from website development and design?</div>,
    answer: <div> <br></br>We have a number of tools and services that you can use such as our AI-Powered Website Builder, CRM Software, Accounting & Finance Software, ERP Software, Sales & Marketing Software and Personal Blogs. To learn more, browse through our Products Section.
    </div>
  }

]

const FAQ = () => {

  const [accordion, setAccordion] = useState(-1);

  const toggleAccordion = (index) => {
    if(index === accordion)
    {
      setAccordion(-1);
      return;
    }
    setAccordion(index);
  }

  return (
    <>
    <h2 className='head-text'><span>Frequently</span> Asked <span>Questions</span></h2>
    <div>
      <div>
        <br></br><br></br>
<div>
  <p align="right"><i>LAST REVISION: [11 November 2022]</i></p>
  <br></br>
    To address any queries you might have about MUNU & MOTI™, we've shared some of our most frequently asked questions. If you still can't find the answers you're looking for, please reach out to us at <a href="mailto:contact@munuandmoti.tech">contact@munuandmoti.tech</a>.
  <br></br><br></br>
</div>
        <div className='accordion__faq'>
          {dataCollection.map((item, index)=>
          <div key={index} onClick={()=>toggleAccordion(index)}>
            <div className='accordion__faq-heading'>
            <h3 className={accordion === index ? "active" : ""}>{item.question}</h3>
            </div>
            <div>
              {accordion === index ? (<><span className='vertical'>
              <RemoveCircleOutlineIcon/>
            </span></>) : (<><span className='vertical'>
              <ControlPointIcon/>
            </span></>)}
            
            </div>
            <div>
              <p className={accordion === index ? "active" : "active not"}>{item.answer}</p>
            </div>

          </div>
          )}
        </div>
      </div>
    </div>
    <br></br>
      {/* <h2 className="head-text"><span>Frequently</span> Asked Questions</h2>

      <div className="app__skills-container">
      <iframe className='pdf' src={FQ} />
        
      </div> */}
    </>
  );
};

export default AppWrap(
  MotionWrap(FAQ, 'app__skills'),
  'FAQ',
  'app__whitebg',
);

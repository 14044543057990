import react from '../assets/react.png';
import solidity from '../assets/solidity.png';
import rust from '../assets/rust.png';

import profile from '../assets/profile.png';
import circle from '../assets/circle.svg';
import logo from '../assets/logo.png';


export default {
  react,
  solidity,
  rust,
  
  profile,
  circle,
  logo,
};
